


































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import ReviewTraces from '@/components/ReviewTraces.vue'
import CbsReportReview from '@/components/CbsReportReview.vue'
import CarInfo from '@/components/carDetail/CarInfo.vue'


@Component({
  components: {
    HeaderBox,
    ReviewTraces,
    CbsReportReview,
    CarInfo
  },
  filters: {
    formatOtherStatus (val) {
      return ['待接车', '待入库审核', '审核通过'][+val-1]
    }
  }
})
export default class carDetail extends Vue {
  videoDialogVisible: boolean = false
  popoverVisible: boolean = false

  procedure: any = {}
  basicInfo = {}
  inspectionInfo: any = {}
  configInfo: any = {}
  carDetail = {}
  cbsOrder: any = {}
  historyCbsOrder = {}
  cbsReportUrl: any = ''
  cbsReviewVisible = false
  reviewTraces = []
  otherInfo = {}
  carInfoImages = []

  mounted () {
    const { center_car_id, auction_id } = this.$route.query;
    this.getDetail(center_car_id, auction_id);
  }

  handleGo (path, params) {
    const { href } = this.$router.resolve({ path, query: params });
    window.open(href, '_blank');
  }

  async getDetail (carId, auction_id) {
    const { code, data } = await this.$rest.carManage.getEnterCarDetail(carId, {
      auction_id
    });
    if (code !== 0) return;
    this.carDetail = data.car_info || {};
    this.basicInfo = data.car_info.basic_info || {};
    this.configInfo = data.car_info.detail.config || {};
    this.procedure = data.car_info.detail.procedure || {};
    this.cbsOrder = data.store_cbs_order || {};
    this.historyCbsOrder = data.history_cbs_order;
    this.otherInfo = data.other_info || {};
    this.inspectionInfo = data.inspection_info || {};
    this.carInfoImages = data.car_info.images || [];
  }

  get cbsFormat () {
    const { report_type_name, reported_at, status } = this.cbsOrder;
    return report_type_name ? `${ report_type_name }（${reported_at}，${status}）` : '未查询';
  }

  handleLookRecord () {
    this.popoverVisible = true
    const { center_car_id } = this.$route.query;
    this.getReviewTraces(center_car_id);
  }

  async getReviewTraces (id) {
    const { code, data } = await this.$rest.carManage.getReviewTraces(id);
    if (code !== 0) return;
    this.reviewTraces = data.infos;
  }

  handleShowCbsReport(report_id) {
    if (!report_id) {
      return false
    }
    const { protocol, host } = window.location
    const reportUrl = this.joinTokenToUrl(`${protocol}//${host}/api/v1/car_management/cbs_report_detail?order_id=${report_id}`);
    this.cbsReportUrl = reportUrl
    this.cbsReviewVisible = true
  }
}
